<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container
      ><v-row align-content="center" justify="center">
        <v-col cols="12" class="main_card_col">
          <v-card elevation="2">
            <v-card-title>
              <span class="ml-2">Videos</span>
              <v-spacer></v-spacer>
              <v-switch
                v-model="show_thumbnail"
                label="Thumbnail"
                dense
                hide-details
                class="mr-4"
              >
              </v-switch>
              <!-- <v-btn
                class="mr-4"
                :disabled="selected_items.length <= 0 || loading"
                @click="downloadAllLinks()"
              >
                <v-icon>mdi-table-arrow-down</v-icon>
              </v-btn> -->
              <v-btn @click="refresh" class="mr-4">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <!-- <v-btn class="mr-4" @click="multi_sort = !multi_sort">
                <v-icon v-if="!multi_sort">mdi-sort-bool-ascending</v-icon>
                <v-icon v-else color="primary">mdi-sort-bool-ascending</v-icon>
              </v-btn> -->
              <v-menu
                v-model="datetime_menu"
                :close-on-content-click="false"
                open-on-hover
                offset-overflow
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" class="filter_button mr-4">
                    <span v-if="!selected_dates.length">all dates</span>

                    <span v-if="selected_dates.length == 1">{{
                      selected_dates[0]
                        .split(" ")[0]
                        .replace("-", ":")
                        .split(":")[1]
                        .replaceAll("-", "/")
                    }}</span>
                    <span v-if="selected_dates.length == 2">{{
                      selected_dates[0]
                        .split(" ")[0]
                        .replace("-", ":")
                        .split(":")[1]
                        .replaceAll("-", "/") +
                      " - " +
                      selected_dates[1]
                        .split(" ")[0]
                        .replace("-", ":")
                        .split(":")[1]
                        .replaceAll("-", "/")
                    }}</span>
                  </v-btn>
                </template>

                <v-card height="380" width="280">
                  <v-row>
                    <v-col>
                      <v-date-picker
                        v-model="date_range"
                        flat
                        range
                        no-title
                        id="video_dp"
                        event-color="#ff9900"
                        :max="todays_date"
                      ></v-date-picker>
                    </v-col>
                  </v-row>
                  <v-row v-if="show_timebar">
                    <v-btn
                      class="filter_button"
                      color="primary"
                      id="saveButton"
                      @click="saveDTFilterMenu"
                    >
                      Save
                    </v-btn>
                    <v-icon
                      v-if="selected_dates.length"
                      @click="reset_dates"
                      class="ml-4 mt-n2"
                      >mdi-filter-off-outline</v-icon
                    >
                  </v-row>
                </v-card>
              </v-menu>

              <!-- @keydown.enter="()=>{if(search_old!=search) updateCameras();}" -->
              <!-- <div class="mt-n4 table_search_field">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                > -->
              <!-- <template v-slot:[`append`]>
                  <v-icon :color="search_old!=search?'primary':''" @click="()=>{if(search_old!=search) updateCameras();}">{{search_old!=search?'mdi-arrow-right':'mdi-magnify'}}</v-icon>
                </template> -->
              <!-- </v-text-field>
              </div> -->
              <div class="mt-n4 ml-2 table_search_field">
                <CameraAutoComplete
                  :all="true"
                  v-model="selected_camera"
                  :owner="selected_customer"
                ></CameraAutoComplete>
              </div>
              <div class="mt-n4" style="width: 250px">
                <CustomerFilter
                  v-model="selected_customer"
                  :disabled="loading"
                ></CustomerFilter>
              </div>
            </v-card-title>
            <v-card-text class="text-center">
              <v-data-table
                :headers="displayed_headers"
                class="elevation-1 entry_table"
                :multi-sort="multi_sort"
                hide-default-footer
                :items="video_list"
                :items-per-page="per_page"
                :options.sync="options"
                :sort-by.sync="sort_by"
                :page.sync="page"
                :sort-desc.sync="sort_desc"
                :server-items-length="video_num"
                :pageCount="video_pages"
                :loading="loading"
                v-model="selected_items"
              >
                <template v-slot:[`item.index`]="{ index }">
                  <span>{{ index + 1 }}</span>
                </template>
              

                <template
                  v-slot:[`item.thumbnail`]="{ item, index }"
                  v-if="show_thumbnail"
                >
                  <div @click="select_video(index)">
                    <video-thumbnail
                      class="thumbnail_image"
                      :width="100"
                      v-model="item.video_path"
                    ></video-thumbnail>
                  </div>
                  <!-- <v-img
                    class="thumbnail_image"
                    width="100"
                      :src="`${
                        cookie_bucket +
                        item.video_path + '_480x270.jpg'
                      }`"
                      @click="select_video(index)"
                    /> -->
                </template>
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <v-tabs class="table_tabs pt-1" v-model="selected_table">
                    <v-tab>Inference</v-tab>
                  </v-tabs>
                  <v-data-footer
                    :pagination="pagination"
                    :options="options"
                    class="table_pagination"
                    @update:options="updateOptions"
                    :items-per-page-options="[50, 100, 200, 500]"
                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                  />
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
    v-model="video_player_dialog"
        :max-width="is_json ? `1400px` : '1000px'"
        min-width="450px"
      v-if="video_list && selected_index != null"
    >
      <v-card>
        <v-card-title v-if="video_list[selected_index]">
          <div id="full_size_ct_div">
            <span
                ><b class="footage_link" v-if="video_list[selected_index].camera_id"
                  >OneCup Ranching</b
                ></span
              >
              <span>
                <b
                  class="footage_link"
                  v-if="video_list[selected_index].camera_id"
                  >{{ video_list[selected_index].camera_id }}</b>
              </span>
            <span class="nav_arrows_f">
              <v-tooltip color="black" bottom v-if="json_file_not_present">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-switch
                      v-model="is_json"
                      label="BETSY"
                      hide-details
                      inset
                      :disabled="json_file_not_present"
                      class="mt-1"
                    >
                    </v-switch>
                  </div>
                </template>
                <div>
                  BETSY Vision is currently unavailable for this video. Please
                  try again later.
                </div>
              </v-tooltip>
              <v-switch
                v-if="!json_file_not_present"
                v-model="is_json"
                label="BETSY"
                hide-details
                inset
                :disabled="!json_loading"
                :loading="json_loading ? 'false' : 'primary'"
                class="mt-1"
              >
              </v-switch>
              <span class="filter_heading">
                <v-icon
                  large
                  class="mt-n1"
                  @click="select_video(selected_index - 1)"
                  v-if="selected_index > 0"
                  >mdi-chevron-left</v-icon
                >
                <v-icon large class="mt-n1" v-else disabled
                  >mdi-chevron-left</v-icon
                >
                <v-icon
                  large
                  class="mt-n1"
                  @click="select_video(selected_index + 1)"
                  v-if="selected_index < video_list.length - 1"
                  >mdi-chevron-right</v-icon
                >
                <v-icon large class="mt-n1" v-else disabled
                  >mdi-chevron-right</v-icon
                ></span
              >
            </span>
          </div>
        </v-card-title>
        <v-card-text>
          <video-player
            preload
            class="vjs-big-play-centered"
            :is_json="is_json"
            :json_file_not_present="json_file_not_present"
            :videoFps="videoFps"
            :portal="portal"
            :json_loading="json_loading"
            :camera_model="camera_model"
            :customerFirstName="customerFirstName"
            :customerLastName="customerLastName"
            :navigation_button_change="navigation_button_change"
            :videoPlayerDialog="video_player_dialog"
            @update-loading="updateJsonLoading"
            :json_url="json_file_url"
            :options="{
              autoplay: true,
              controls: false,
              fluid: true,
            }"
            />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";
import moment from "moment-timezone";
import CustomerFilter from "../components/CustomerFilter.vue";
import CameraAutoComplete from "../components/CameraAutoComplete.vue";
import VideoPlayer from "@/components/video_player/VideoPlayer.vue";
// import JSZip from "jszip";
import { eventBus } from "../main.js";
import videojs from "video.js";
import Vue from "vue";
import VideoThumbnail from "../components/VideoThumbnail.vue";

// @vuese
// @group Views
export default {
  components: {
    navbar,
    sidenav,
    CustomerFilter,
    CameraAutoComplete,
    VideoPlayer,
    VideoThumbnail,
  },
  data() {
    return {
      loading: false,
      selected_table: 0,

      multi_sort: false,

      page: 1,
      per_page: 100,
      // video_pages: 0,

      // video_num: 0,
      // video_list: [],
      options: {},

      sort_by: ["media_timestamp"],
      sort_desc: true,

      search: "",
      selected_customer: "All Customers",
      show_timebar: false,

      date_range: [],
      selected_dates: [],
      datetime_menu: false,
      todays_date: moment().format("YYYY-MM-DD"),
      selected_index: null,

      dialog: false,
      json_file_not_present: false,
      json_data: "",
      is_json: false,
      json_loading: false,
      navigation_button_change: false,
      videoFps: 0,
      portal: "admin",
      camera_model: "",
      selected_camera: null,
      show_thumbnail: true,
      video_player_dialog: false,
      timeStamp: "",
      json_file_url: "",
      customerFirstName: "",
      customerLastName: "",

      selected_items: [],
    };
  },
  created() {
    eventBus.$on("player_ready", () => {
      this.playVideo();
    });
  },
  methods: {
    updateJsonLoading(newValue) {
      this.json_loading = newValue;
    },
    async jsonPresent() {
      const url = this.video_list[this.selected_index].video_path;
      this.json_file_not_present = false;

      const json_file_source =
        process.env.VUE_APP_COOKIE_BUCKET + url + ".json.zip";

        try {
        const response = await fetch(json_file_source, {
          credentials: "include",
        });
        this.is_json = false;
        if (!response.ok) {
          this.json_file_not_present = true;
          this.json_data = "";
          return;
        } else {
          this.is_json = true;
          this.json_file_not_present = false;
        }
      } catch (error) {
        console.error("Error fetching JSON:", error);
      }
    },
    refresh() {
      this.updateVideos();
    },
    select_video(index) {
      this.selected_index = index;
      this.video_player_dialog = true;
      this.navigation_button_change = !this.navigation_button_change;
      try {
        this.json_file_url = this.video_list[this.selected_index].video_path;
        this.jsonPresent();
        this.playVideo();
      } catch {
        console.log("err");
      }
    },
    async playVideo() {
      var player = videojs(document.querySelector(".video-js"));
      const url = this.video_list[this.selected_index].video_path;
      this.videoFps = this.video_list[this.selected_index]["fps"];
      var source =
        process.env.VUE_APP_COOKIE_BUCKET + url.replaceAll("+", "/") + ".m3u8";

      eventBus.$emit("sourceChanged", source);
      var res = await fetch(source, { credentials: "include" });
      if (res.status == 403) {
        var arr = url.replaceAll("+", "/").split("/");
        arr.splice(1, 0, "ftp");
        console.log(arr);
        source = process.env.VUE_APP_COOKIE_BUCKET + arr.join("/") + ".m3u8";
        res = await fetch(source, { credentials: "include" });
      }
      const tn = await res.text();
      const tn_arr = tn.split(/#EXTINF:/);
      tn_arr.shift();
      tn_arr.pop();
      // tn_arr.pop();
      const duration_sec = this.video_list[this.selected_index]["num_frames"]/this.video_list[this.selected_index]["fps"];

      var v_tn = {};
      for (const i in tn_arr) {
        v_tn[
          parseInt(tn_arr[i].slice(-8, -4).replace("-", ""), 10) * duration_sec
        ] = {
          src:
            process.env.VUE_APP_COOKIE_BUCKET +
            url.replaceAll("+", "/").slice(0, -36) +
            "hls/" +
            tn_arr[i]
              .slice(10, 50)
              .replace(".", "")
              .replace("ts", "")
              .replace("t", "")
              .replace("s", "") +
            ".jpg",
        };
      }
      eventBus.$emit("thumbnailPicArray", v_tn);

      player.src({
        src: source,
        type: "application/x-mpegURL",
        withCredentials: true,
      });
      if (this.$video_start) {
        this.select_tag(this.$video_start[0]);
        Vue.prototype.$video_start = false;
      }
    },
    get_url(thumbnail, cam_id) {
      let temp_array = thumbnail.split(cam_id + "/");
      let betsy_thumbnail =
        temp_array[0] + "betsy-layer1/" + cam_id + "/" + temp_array[1];
      return betsy_thumbnail;
    },
    reset_dates() {
      this.selected_dates = [];
      this.date_range = [];
      this.updateVideos();
    },
    saveDTFilterMenu() {
      this.datetime_menu = false;
      if (this.date_range.length == 2) {
        if (
          moment(this.date_range[0], "YYYY-MM-DD").isAfter(
            moment(this.date_range[1], "YYYY-MM-DD")
          )
        ) {
          this.selected_dates = [
            this.date_range[1] + " " + "00:00:01",
            this.date_range[0] + " " + "23:59:59",
          ];
        } else {
          this.selected_dates = [
            this.date_range[0] + " " + "00:00:01",
            this.date_range[1] + " " + "23:59:59",
          ];
        }
      } else if (this.date_range.length == 1) {
        this.selected_dates = [this.date_range[0] + " " + "00:00:01"];
      } else {
        this.selected_dates = [];
      }
      this.updateVideos();
    },
    async updateVideos() {
      this.search_old = this.search;
      this.loading = true;
      let where = {};
      let order_by = {};

      if (this.selected_customer && this.selected_customer != "All Customers") {
        where.customer_id = { _eq: this.selected_customer };
      }

      if (this.selected_camera) {
        where.camera_id = { _eq: this.selected_camera };
      }

      where.media_type = { _eq: "VIDEO" };
      if (this.selected_dates.length > 0) {
        if (this.selected_dates.length == 2) {
          let dateStart = moment(
            this.selected_dates[0],
            "YYYY-MM-DDTHH"
          ).format("YYYY-MM-DDTHH:ss:mm");

          let dateEnd =
            moment(this.selected_dates[1], "YYYY-MM-DDTHH").format(
              "YYYY-MM-DD"
            ) + "T23:59:59";

          where.media_timestamp = {
            _gte: dateStart,
            _lte: dateEnd,
          };
        } else if (this.selected_dates.length == 1) {
          where.media_timestamp = {
            _gte: moment(this.selected_dates[0], "YYYY-MM-DDTHH").format(
              "YYYY-MM-DDTHH:ss:mm"
            ),
            _lt: moment(this.selected_dates[0], "YYYY-MM-DDTHH")
              .add(1, "d")
              .format("YYYY-MM-DDTHH:ss:mm"),
          };
        }
      }

      var offset = 0;
      var limit = 0;

      offset = (this.options.page - 1) * this.options.itemsPerPage;
      limit = this.options.itemsPerPage;
      
      this.options.sortBy.forEach((e, i) => {
        order_by[e] = this.options.sortDesc[i] ? "desc" : "asc";
      });
      

      let params = {
        where: where,
        order_by: order_by,
        offset: offset, 
        limit:limit,
      }
  
      this.$store.dispatch("DDB_GET_VIDEOS_VIDEOTABLE", params).then(()=> {
        this.loading = false;
      })
    },
  },
  watch: {
    options: {
      handler() {
        this.updateVideos();
      },
      deep: true,
    },
    date_range() {
      if (this.date_range.length > 0) {
        this.show_timebar = true;
      } else {
        this.show_timebar = false;
      }
    },
    selected_customer() {
      this.updateVideos();
    },
    selected_camera() {
      this.updateVideos();
    },
  },
  computed: {
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET;
    },
    video_num() {
     return  this.$store.getters.getVideosVideoTable[1]
    },
    video_pages() {
      return (this.$store.getters.getVideosVideoTable[1]/this.options.itemsPerPage)
    },
    video_list() {
      if (this.$store.getters.getVideosVideoTable) {
        let allCustomers = this.$store.getters.getCustomerObjects;
         var paths =  this.$store.getters.getVideosVideoTable[0];
         for(let i = 0; i < paths?.length ; i++) {
          let foundCustomerObject = allCustomers.find(customer => customer.id ===  paths[i].customer_id);
          if(foundCustomerObject) {
            paths[i].customer_name = `${foundCustomerObject.first_name} ${foundCustomerObject.last_name}`
          }
        }
        return paths
      } else {
        return [];
      }
    },
    displayed_headers() {
      return this.headers_inference;
    },
    headers_inference() {
      return [
        { text: "#", value: "index", sortable: false, width: "20px" },
        { text: "", value: "thumbnail", sortable: false },
        { text: "Camera ID", value: "camera_id",sortable: false},
        { text: "Customer Name", value: "customer_name",sortable: false},
        { text: "FPS", value: "fps", sortable: false },
        { text: "Height", value: "height", sortable: false },
        { text: "Width", value: "width", sortable: false },
        { text: "Upload Time", value: "uploaded_at", sortable: false },
        { text: "Date Time", value: "video_time", sortable: false  },
      ];
    },
  
  },
};
</script>

<style scoped>
.filter_button {
  font-size: 14px;
  text-transform: none;
}

#saveButton {
  margin-bottom: 10px;
  margin-left: 30px;
}

.thumbnail_image {
  cursor: pointer;
}

#full_size_ct_div {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}
#full_size_ct_div span{
  color: #0090A4;
}
</style>
